import React from 'react';
// import Header from './Component/CommonComponent/Header';
import ResultDisplay from './Component/ResultDisplay';
import Data from './Component/CommonComponent/Data/QuestionData'

const Result = () => {
        return (
        <>
          <ResultDisplay data={Data} />    
        </>
    )
}

export default Result;
